import React, { useEffect, useState, useRef } from 'react';
import { trans } from '../../../../_providers/Translation';

import { useHistory, useLocation, Link, useParams } from 'react-router-dom';
import Api from '../../../../_helpers/Api';
import loader from '../../../../assets/img/loader.svg';
import Filter from './FilterStats';
import { useAuthDataContext } from '../../../../_providers/Auth';
// import style from '../../../assets/css/accounts.css'
import * as Constants from '../../../../_config/app';
import Pagination from '../../../Pagination';
import NoDataFound from '../../../partials/NoDataFound';
import ChartData from './ChartData';

//images
import checkIcon from '../../../../assets/img/icons/check.png'
import closeIcon from '../../../../assets/img/icons/close.png'
import Question from '../../../modals/messages/Question';
import Success from '../../../modals/messages/Success';

let timeout;

function Stats() {

    const auth = useAuthDataContext();
    const params = useParams();
    const history = useHistory();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const [state, setState] = useState({
        loading: true,
        data: [],
        totals: {},
        total: 0,
        pages: 0,
        page: 1,
        filter: {},
        setFilter: false,
        update: false,
        summary: true,
    });

    const [selected, setSelected] = useState([]);

    const successSendModalRef = useRef(null);
    const questionModalRef = useRef(null);

    useEffect(() => {
        setState(prev => ({
            ...prev,
            page: searchParams.get('page') || 1,
            filter: {
                ...prev.filter,
                start: searchParams.get('start') || '',
                end: searchParams.get('end') || '',
                group: searchParams.get('group') || '',
            },
            update: new Date().getTime()
        }))

    }, [location.search])

    useEffect(() => {

        if (state.setFilter) {
            Object.entries(state.filter).map(filter => {
                // if (filter[1]) {
                searchParams.set(filter[0], filter[1]);
                // }
            });

            let url = '?' + searchParams.toString();

            history.push(url);
        }

    }, [state.setFilter]);

    useEffect(() => {
        if (state.update) {
            loadData();
        }
    }, [state.update]);

    const loadData = () => {
        setState(prev => ({
            ...prev,
            loading: true
        }));

        let url = 'partners/selfemployed/payouts?employee_id=' + params.id + '&page=' + state.page;

        Object.entries(state.filter).map(filter => {
            url += (url.indexOf('?') > -1 ? '&' : '?') + filter[0] + '=' + filter[1];
        });

        Api.get(url)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: res.data.items,
                    totals: res.data.totals,
                    total: res.data.total,
                    pages: res.data.pages,
                    filter: {
                        ...prev.filter,
                        ...res.data.filter,
                    },
                    loading: false
                }));
            });
    }

    const handleSearch = (key, val, delay = 300) => {

        clearTimeout(timeout);

        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                [key]: val,
            }
        }));

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                setFilter: new Date().getTime()
            }));
        }, delay);

    }

    const refresh = (reset = false) => {
        setState(prev => ({ ...prev, page: reset ? 1 : prev.page, update: new Date().getTime() }));
    }

    const handlePage = page => {
        setState(prev => ({ ...prev, page: page.selected + 1, update: new Date().getTime() }));
    }

    const toggleSummaryData = val => {
        setState(prev => ({
            ...prev,
            summary: val
        }));
    }

    const handleExcel = () => {
        let url = `${Constants.App.api}accounts/export-profit-vatreport?token=${auth.token()}`;

        Object.entries(state.filter).map(filter => {
            url += (url.indexOf('?') > -1 ? '&' : '?') + filter[0] + '=' + filter[1];
        });

        window.open(url, '_blank', 'noopener,noreferrer')

    }

    const toggleRow = (id) => {
        if (isSelected(id)) {
            setSelected(prev => prev.filter(i => Number(i) !== Number(id)));
        } else {
            setSelected(prev => prev.concat(Number(id)));
        }
    }

    const isSelected = id => {
        return selected.indexOf(Number(id)) > -1;
    }

    const toggleAll = () => {
        if (isAllSelected()) {
            setSelected([]);
        } else {
            setSelected(state.data.map(c => Number(c.id)));
        }
    }

    const isAllSelected = () => {
        let selected = true;

        state.data.map(c => {
            if (!isSelected(c.id)) {
                selected = false;
            }
        });

        return selected;
    }

    const handleMarkAsPaid = () => {
        let question = questionModalRef.current;

        question.open();
        question.onSuccess(() => {
            markAsPaid();
        });
    }

    const markAsPaid = () => {
        Api.post('partners/selfemployed/set-reservation-paid', {
            ids: selected,
        }).then(() => {
            onSuccess();
        })
    }

    const handleMarkAsUnpaid = () => {
        let question = questionModalRef.current;

        question.open();
        question.onSuccess(() => {
            markAsUnpaid();
        });
    }

    const markAsUnpaid = () => {
        Api.post('partners/selfemployed/set-reservation-unpaid', {
            ids: selected,
        }).then(() => {
            onSuccess();
        })
    }

    const onSuccess = () => {
        let modal = successSendModalRef.current;

        modal.open();
        modal.onClose(() => {
            refresh(false);
            setSelected([]);
        });
    }

    return (
        <>
            <Question
                ref={questionModalRef}
                mainMessage={trans('headings.question15')}
            />

            <Success
                ref={successSendModalRef}
                mainMessage={trans('messages.success')}
            />

            <Filter
                filter={state.filter}
                handleSearch={handleSearch}
                summary={state.summary}
                toggleSummaryData={toggleSummaryData}
            />

            <br />

            {state.loading
                ?
                <img className="loader" src={loader} />
                :
                state.data.length === 0
                    ?
                    <NoDataFound />
                    :
                    <>
                        <ChartData
                            filter={state.filter}
                            update={state.update}
                            summary={state.summary}
                        />

                        {/* <button onClick={handleExcel}>{trans('buttons.download')}</button>

                        <br /> */}

                        <div className="table-scroller">
                            <table>
                                <thead>
                                    <tr>
                                        {/* <th style={{ width: '20px' }}>
                                            <input
                                                type="checkbox"
                                                checked={isAllSelected()}
                                                onChange={e => toggleAll()}
                                            />
                                        </th> */}
                                        <th>ID</th>
                                        <th>{trans('tables.client')}</th>
                                        <th>{trans('tables.address')}</th>
                                        <th>{trans('tables.refNo')}</th>
                                        <th>{trans('tables.visitDate')}</th>
                                        <th className="right">{trans('tables.grossPrice')}</th>
                                        <th>{trans('tables.initialStatus')}</th>
                                        <th>{trans('tables.finalStatus')}</th>
                                        <th className="right">{trans('tables.amountDue')}</th>
                                        <th className="center">{trans('tables.isPaid')}</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {state.data.map(c =>
                                        <tr key={c.id}>
                                            {/* <td>
                                                <input
                                                    type="checkbox"
                                                    checked={isSelected(c.id)}
                                                    onChange={e => toggleRow(c.id)}
                                                />
                                            </td> */}
                                            <td>
                                                {c.id}
                                            </td>
                                            <td>
                                                {c?.customer
                                                    ?
                                                    `${c.customer?.name} ${c.customer?.lastname}`
                                                    :
                                                    ''
                                                }
                                            </td>
                                            <td>
                                                {c.full_address}
                                            </td>
                                            <td>
                                                <Link
                                                    to={`/reservations/edit/${c.id}`}
                                                    className="btn"
                                                    style={{ background: c.color, color: c.text_color }}
                                                >
                                                    {c.reference_code}
                                                </Link>
                                            </td>
                                            <td>
                                                {c.visit_date_dmy}
                                            </td>
                                            <td className="right">
                                                {c.total_gross_price_formatted || c.price_formatted} {c.currency && c.currency.name}
                                            </td>
                                            <td>
                                                {c.initialstatus?.translation?.name}
                                            </td>
                                            <td>
                                                {c.finalstatus?.translation?.name}
                                            </td>
                                            <td className="right">
                                                {c.employee_price_formatted} {c.currency && c.currency.name}
                                            </td>
                                            <td className="center">
                                                {c.employee_paid
                                                    ?
                                                    <img src={checkIcon} height="12" />
                                                    :
                                                    <img src={closeIcon} height="12" />
                                                }
                                            </td>
                                        </tr>
                                    )}
                                    <tr>
                                        <td colSpan={8}>
                                            {trans('tables.total')}
                                        </td>
                                        <td className="right">
                                            {state.totals.employee_price} {state.totals.currency?.name}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        {!state.loading &&
                            <Pagination
                                page={state.page}
                                pages={state.pages}
                                handlePage={handlePage}
                            />
                        }

                        {/* <br />

                        <div className="actions">
                            <button onClick={handleMarkAsPaid} disabled={selected.length === 0}>{trans('buttons.markAsPaid')}</button>
                            <button onClick={handleMarkAsUnpaid} disabled={selected.length === 0}>{trans('buttons.markAsUnpaid')}</button>
                        </div> */}
                    </>
            }

        </>
    )
}

export default Stats;