import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { trans } from '../../../../_providers/Translation';

import Api from '../../../../_helpers/Api';
import Chart from "react-google-charts";
import loader from '../../../../assets/img/loader.svg';

function ChartData(props) {

    const params = useParams()

    const [state, setState] = useState({
        loading: true,
        data: {
            dates: [],
            employees: []
        },
        employees: [],
        // summary: true,
        key: true,
    });

    useEffect(() => {
        if (props.update) {
            loadData();
        }
    }, [props.update]);

    useEffect(() => {

        if (!state.data.dates) {
            return;
        }

        let label = trans('random.day');

        let chart;

        // обобщена 
        if (props.summary) {
            chart = [
                [label, trans('tables.amountDue'), trans('tables.paidPrice')],
            ];

            state.data.dates.map(d => {
                let arr = [
                    d.label, d.price, d.paid_price,
                ];

                chart.push(arr);
            });
        }

        setState(prev => ({
            ...prev,
            chart: chart
        }))

    }, [state.data, props.summary])

    const loadData = () => {
        setState(prev => ({
            ...prev,
            loading: true
        }));

        let url = 'partners/selfemployed/payouts-chart?employee_id=' + params.id;

        Object.entries(props.filter).map(filter => {
            url += (url.indexOf('?') > -1 ? '&' : '?') + filter[0] + '=' + filter[1];
        });

        Api.get(url)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: res.data,
                    loading: false
                }));
            });
    }

    const getColumns = () => {
        let arr = [];

        arr.push({
            type: 'NumberFormat',
            column: 1,
            options: {
                // prefix: '$',
                negativeParens: false,
            }
        })

        arr.push({
            type: 'NumberFormat',
            column: 2,
            options: {
                // prefix: '$',
                negativeParens: false,
            }
        })

        return arr;
    }


    return (
        <>
            {state.loading
                ?
                <img className="loader" src={loader} />
                :
                <div
                    className="chart"
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        background: '#fff',
                        padding: '5px'
                    }}>
                    <Chart
                        width={window.innerWidth <= 1024 ? '900px' : '99%'}
                        height={'350px'}
                        chartType="Bar"
                        className="animated-chart-vertical animated-chart-start"
                        loader={'...'}
                        data={state.chart}
                        options={{
                            // Material design options
                            chart: {
                                title: trans('headings.payments'),
                                subtitle: state.data.currency,
                            },

                            legend: {
                                position: 'left'
                            },
                            allowHtml: true,
                            showRowNumber: true,
                        }}
                        // custom attributes
                        rootProps={{ 'data-testid': '2' }}
                        chartEvents={[
                            {
                                eventName: "ready",
                                callback: ({ chartWrapper, google }) => {
                                    const chartEl = chartWrapper.getChart().container;
                                    setTimeout(() => {
                                        chartEl.classList.remove('animated-chart-start')
                                    }, 100)
                                },
                            }
                        ]}
                        formatters={getColumns()}
                    />
                </div>
            }

            <br />
        </>
    )
}

export default ChartData;